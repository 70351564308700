import { Vue, Component } from 'vue-property-decorator';
import { IdentRouteNames } from '@/modules/identification/identRouteNames';
import { State, BlockchainStateSlice } from '@/store/models';
import { State as StateClass, Getter, Action } from 'vuex-class';
import { ethers } from 'ethers';
import detectEthereumProvider from '@metamask/detect-provider';
import { ADD_TOAST_MESSAGE as addToastMessage } from 'vuex-toast';
import Loader from '@/components/common/loader/Loader.vue';
import Support from '@/components/common/support/Support.vue';
import { IdentificationRequestStatus } from '@/store/models/identificationRequest';
import { intercom, name } from '../../../../../whitelabel.config';

@Component({
  components: {
    Loader,
    Support,
  },
})
export default class AccountSettingsIdentification extends Vue {
  intercom = intercom;
  clientName = name;
  IdentRouteNames = IdentRouteNames;

  toggleSupport: boolean = false;

  @StateClass investor!: State['investor'];
  @StateClass identificationRequest!: State['identificationRequest'];
  @StateClass blockchain!: BlockchainStateSlice;

  @Getter isInvestor!: boolean;

  @Action(addToastMessage) addToastMessage!: Function;

  get pendingIdentificationRequest(): boolean {
    return this.identificationRequest?.status === IdentificationRequestStatus.Initial;
  }

  get rejectedIdentificationRequest(): boolean {
    return this.identificationRequest?.status === IdentificationRequestStatus.Rejected;
  }

  async onMetamaskClick(): Promise<void> {
    if (!this.blockchain.isMetamaskAccountConnected) {
      const providerDetected = await detectEthereumProvider({ mustBeMetaMask: true });
      // @ts-ignore
      if (providerDetected) {
        // @ts-ignore
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        await provider.send('eth_requestAccounts', []);
      } else {
        this.addToastMessage({
          text: 'Make sure you have MetaMask extension installed on your browser.',
          type: 'danger',
        });
      }
    }
  }
}
