import { render, staticRenderFns } from "./AccountMetamask.html?vue&type=template&id=2d8eb6b2&scoped=true&"
import script from "./AccountMetamask.ts?vue&type=script&lang=ts&"
export * from "./AccountMetamask.ts?vue&type=script&lang=ts&"
import style0 from "@/scss/components/account/_metamask.scss?vue&type=style&index=0&id=2d8eb6b2&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d8eb6b2",
  null
  
)

export default component.exports