import { Vue, Component } from 'vue-property-decorator';
import { State as StateClass, Getter } from 'vuex-class';
import { State } from '@/store/models';
import { IdentificationRequestStatus } from '@/store/models/identificationRequest';

@Component({
  components: {
  },
})
export default class AccountUpgrade extends Vue {
  @StateClass identificationRequest!: State['identificationRequest'];
  @StateClass user!: State['user'];

  @Getter isEligibleToInvest!: Function;

  get pendingIdentificationRequest(): boolean {
    return this.identificationRequest?.status === IdentificationRequestStatus.Initial;
  }

  get pendingBlockchainWhitelist(): boolean {
    // when investorMetamaskAccountAddress exists in the User and the typeof equals to string it means that the
    // investor has connected his MetaMask account and is now ready to be whitelisted into the blockchain by the Admin
    return !this.user!.blockchainWhitelisted && typeof this.user!.investorMetamaskAccountAddress === 'string';
  }

  get rejectedIdentificationRequest(): boolean {
    return this.identificationRequest?.status === IdentificationRequestStatus.Rejected;
  }

  dismissInfobox(): void {
    if (this.pendingBlockchainWhitelist) {
      localStorage.setItem('showWhitelistingInfobox', 'false');
      this.$emit('dismiss-blockchain-whitelisting');
    } else {
      localStorage.setItem('showUpgradeInfobox', 'false');
      this.$emit('dismiss');
    }
  }
}
