import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { State as StateClass, Getter, Action } from 'vuex-class';
import detectEthereumProvider from '@metamask/detect-provider';
import { ethers } from 'ethers';
import { ADD_TOAST_MESSAGE as addToastMessage } from 'vuex-toast';
import LazyImage from '@/components/common/lazyImage/LazyImage.vue';
import Loader from '@/components/common/loader/Loader.vue';
import WithDownloads from '@/components/wrappers/downloads/WithDownloads.vue';
import { InvestmentsArray } from '@/store/modules/investments';
import { State, BlockchainStateSlice } from '@/store/models';
import { DownloadPath } from '@/store/modules/downloads/downloads';
import { Investment, Payment } from '@/store/models/investment';
import { Asset } from '@/store/models/asset';
import * as MUTATIONS from '@/store/constants';

// const VueScrollTo = require('vue-scrollto');

// Vue.use(VueScrollTo);
@Component({
  components: {
    Loader,
    WithDownloads,
    LazyImage,
  },
})

export default class AccountInvestments extends Vue {
  @Prop({ }) investment!: Investment;
  @Prop({ }) asset!: Asset;

  pageSize: number = 5;
  currentPage: number = this.pageSize;
  showPayments: { [key: string]: { [key: string]: any} } = {};
  maxPayments: number = 5; // max payments shown per investment
  claimingUnits: boolean = false;

  @Action syncBlockchainTokens!: Function;
  @Action claimBlockchainTokens!: Function;
  @Action(addToastMessage) addToastMessage!: Function;

  @StateClass assets!: State['assets'];
  // Workaround for Vue's reactivity caveat.
  @StateClass((state): State['payments'] => [...state.payments]) payments!: State['payments'];
  @StateClass user!: State['user'];
  @StateClass blockchain!: BlockchainStateSlice;

  @Getter investmentsLoadMore!: Function;
  @Getter getAssetById!: Function;
  @Getter isDownloadsProcessing!: Function;
  @Getter getPaidPaymentsByInvestmentId!: Function;

  @Watch('blockchain.operations', { deep: true })
  onBlockchainOperationsChange(newBlockchainOperations: BlockchainStateSlice['operations']): void {
    if (newBlockchainOperations!.name === MUTATIONS.CLAIM_BLOCKCHAIN_TOKENS_PROCESSING) {
      this.claimingUnits = true;
    } else if (newBlockchainOperations!.name === MUTATIONS.CLAIM_BLOCKCHAIN_TOKENS_SUCCESS) {
      this.claimingUnits = false;
      this.addToastMessage({
        text: 'Tokens claimed with success in the blockchain',
        type: 'success',
      });
      // Retrieve info from API that takes a bit to be updated
      if (this.user) {
        setTimeout((): void => {
          this.syncBlockchainTokens(this.user!.id);
        }, 500);
      }
    } else if (newBlockchainOperations!.name === MUTATIONS.CLAIM_BLOCKCHAIN_TOKENS_ERROR) {
      this.claimingUnits = false;
      this.addToastMessage({
        text: 'Error while claiming the tokens in the blockchain',
        type: 'danger',
      });
    }
  }

  get claimingTokensFromBlockchainData(): { paymentId: string } | undefined {
    if (this.claimingUnits) {
      return this.blockchain?.operations?.payload;
    }

    return undefined;
  }

  /**
   * Computed property to get the investments.
   */
  get investments(): InvestmentsArray<State['investments']> {
    return this.investmentsLoadMore(this.currentPage);
  }

  /**
   * Returns whether we've reached the
   * end of the transaction list on
   * the frontend.
   *
   * @returns {boolean}
   */
  get endOfTransactionsList(): boolean {
    return this.investments && this.investments.length === this.investments.totalLength;
  }

  get investmentsDownloadsProcessing(): boolean {
    const path: DownloadPath = { collection: 'assets', id: '', properties: 'images' };
    return this.investments.some((investment): boolean => this.isDownloadsProcessing({ ...path, id: (investment as any).assetId }));
  }

  async connectMetamask(): Promise<void> {
    const providerDetected = await detectEthereumProvider({ mustBeMetaMask: true });
    // @ts-ignore
    if (providerDetected) {
      // @ts-ignore
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      await provider.send('eth_requestAccounts', []);
    } else {
      this.addToastMessage({
        text: 'Make sure you have MetaMask extension installed on your browser.',
        type: 'danger',
      });
    }
  }

  clickPreventerClaimTokens(blockchain, user, payment, investmentId): boolean {
    return blockchain.isMetamaskAccountConnected
      && user.blockchainWhitelisted
      ? this.claimBlockchainTokens({
        paymentId: payment.id,
        tokensAlreadyClaimed: payment.blockchainTokensClaimed,
        blockchainTokensIds: payment.blockchainTokensIds,
        investmentId,
        investorId: user.id,
      })
      : this.connectMetamask();
  }

  showAllPayments(id: string): void {
    const showPayments = this.showPayments;
    showPayments[id] = showPayments[id]
      ? { ...showPayments[id], max: !showPayments[id].max }
      : { max: true };
    Vue.set(this.showPayments, id, showPayments[id]);
  }

  onInvestmentExpand(id: string): void {
    const showPayments = { ...this.showPayments };
    showPayments[id] = showPayments[id] ?
      { ...showPayments[id], expand: !showPayments[id].expand }
      : { expand: true };
    Vue.set(this.showPayments, id, showPayments[id]);
  }

  reverseArray(array: any[]): any[] {
    return [...array].reverse();
  }

  // Opening the support box from AccountDashboard
  handleSupport(): void {
    this.$emit('handle-support-event');
  }

  loadMoreInvestments(): void {
    this.currentPage += this.pageSize;
  }

  isPaymentEnded(payment: Payment): boolean {
    return payment.ended ? ((payment.ended.seconds * 1000) - Date.now()) <= 0 : false;
  }
}
